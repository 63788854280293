<template>
  <v-card class="rounded-10 mx-md-2 mx-sm-0" flat min-height="500">
    <v-card-title class="white py-3 primary--text rounded-bl-0 rounded-br-0">
      <v-row
        dense
        v-if="product"
        class="fw-500 my-3 fs-25 d-flex align-center justify-space-between"
      >
        <span>{{ product.title | ucwords }}</span>
        <v-btn depressed @click="goBack" color="primary" class="text-none px-5">
          Back
        </v-btn>
      </v-row>
    </v-card-title>
    <v-card-text
      v-if="product"
      style="background: #f1efef; min-height: 500px"
      class="rounded-2 d-flex"
    >
      <v-row
        class="fill-height align-self-center"
        dense
        align="stretch"
        justify="center"
      >
        <v-col :cols="mdAndUp ? 4 : 12" class="d-flex flex-column align-center">
          <div class="fullwidth d-flex align-center py-3">
            <span class="fw-800 fs-30 primary--text mx-auto">
              {{ product.adjusted_sell_price | money }}
            </span>
            <v-menu
              v-if="canAddPrice(product)"
              bottom
              left
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-cash-plus</v-icon>
                </v-btn>
              </template>
              <v-card flat width="400" class="rounded-10">
                <v-card-text class="d-flex fullwidth align-center flex-column">
                  <div
                    class="d-flex fullwidth primary--text fw-500 fs-15 align-center mb-1 justify-space-between"
                  >
                    <span> Original price :</span>
                    <span> {{ product.sell_price | money }} </span>
                  </div>
                  <div
                    v-if="product.applied_price"
                    class="d-flex fullwidth primary--text fw-500 fs-15 align-center mb-1 justify-space-between"
                  >
                    <span> Additional price type : </span>
                    <span>
                      {{ product.applied_price.via | ucwords }}
                      <span v-if="product.applied_price.via === 'percentage'">
                        ({{ product.applied_price.value }})
                      </span>
                    </span>
                  </div>
                  <div
                    class="d-flex fullwidth primary--text fw-500 fs-15 align-center mb-1 justify-space-between"
                  >
                    <span> Less price amount: </span>
                    <span>
                      {{
                        (product.adjusted_sell_price - product.sell_price)
                          | money
                      }}
                    </span>
                  </div>
                  <div
                    v-if="
                      product.applied_price &&
                      product.applied_price.pricable_type == 'App\\GraphicType'
                    "
                    class="d-flex fullwidth primary--text fw-500 fs-15 align-center mb-1 justify-space-between"
                  >
                    <span> Graphic Type: </span>
                    <span>
                      {{ product.applied_price.pricable.title }}
                    </span>
                  </div>
                  <v-btn
                    v-if="
                      product.applied_price &&
                      product.applied_price.pricable_type === 'App\\Product'
                    "
                    color="primary"
                    small
                    @click.stop="product_price_remove_dialog = true"
                    depressed
                    class="fs-13 mb-1 mt-4 ls-unset mx-auto text-none"
                  >
                    Remove Additional Price
                  </v-btn>
                  <v-btn
                    v-else-if="
                      product.applied_price &&
                      product.applied_price.pricable_type !== 'App\\Product'
                    "
                    color="primary"
                    small
                    @click.stop="admin_price_by_product_dialog = true"
                    depressed
                    class="fs-13 mb-1 mt-4 ls-unset mx-auto text-none"
                  >
                    Add or Update Additional Price
                  </v-btn>
                  <v-btn
                    v-else-if="!product.applied_price"
                    color="primary"
                    small
                    @click.stop="admin_price_by_product_dialog = true"
                    depressed
                    class="fs-13 mb-1 mt-4 ls-unset mx-auto text-none"
                  >
                    Add Additional Price
                  </v-btn>
                </v-card-text>
                <v-card-actions
                  class="primary--text fw-500 fs-12 px-3 text-center"
                >
                  Price added on individual product will override the price that
                  added on graphic types
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
          <Attachment
            v-if="activeAttachment"
            :item="activeAttachment"
            :prefer-thumb="false"
            animated
            classes="ma-auto pa-2 border-2 rounded-9"
            :styles="`max-height:500px;background:#e0e0e0; min-height:300px;`"
          ></Attachment>
          <Empty
            v-else
            icon="mdi-image-off-outline"
            style="height: 300px; width: 300px"
            headline=""
            class="border-3 border-color--primary rounded-10"
          ></Empty>
          <v-row
            dense
            class="my-2 wrap d-flex fullwidth"
            align="center"
            justify="center"
          >
            <v-btn
              class="text-none mr-2 px-5 fw-700 fs-20 primary--text"
              height="60"
              @click.stop="handleLiking"
            >
              {{ product.is_liked ? 'Unlike' : 'Like' }}
              <v-icon
                class="ml-5"
                size="25"
                right
                :color="product.is_liked ? 'error' : 'secondary'"
              >
                mdi-heart
              </v-icon>
            </v-btn>
            <v-btn
              v-if="!is_product_owner"
              class="text-none ml-2 px-5 fw-700 fs-20 primary--text"
              height="60"
              @click.stop="handleOrdering"
            >
              Create Order
              <v-icon class="ml-5" size="25" right>mdi-play</v-icon>
            </v-btn>
            <v-btn
              v-else-if="is_product_owner"
              class="text-none ml-2 px-5 fw-700 fs-20 primary--text"
              height="60"
              @click.stop="handleEdit"
            >
              Edit Product
              <v-icon class="ml-5" size="25" right>mdi-pencil</v-icon>
            </v-btn>
          </v-row>
        </v-col>
        <v-col
          v-if="product.previews.length > 1"
          :cols="mdAndUp ? 7 : 12"
          class="d-flex align-center"
        >
          <v-slide-group class="mt-3" multiple show-arrows>
            <v-slide-item v-for="preview in product.previews" :key="preview.id">
              <v-card
                flat
                class="mx-2 d-flex align-center"
                max-height="200"
                max-width="150"
                @click.stop="activeAttachment = preview"
              >
                <Attachment
                  :item="preview"
                  prefer-thumb
                  @attachment-clicked="activeAttachment = preview"
                  styles="background:#e0e0e0;"
                  :classes="`ma-auto pa-2 border-2 rounded-9`"
                ></Attachment>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>
    </v-card-text>
    <Empty
      v-else-if="!loading"
      class="fill-height"
      icon="mdi-image-off"
      headline="Graphics not found!"
    >
    </Empty>

    <OrderDialog v-model="order_dialog" :product="product"></OrderDialog>
    <AdminPriceByProductDialog
      v-model="admin_price_by_product_dialog"
      :product="product"
      @save="handleSaveAdminPriceByProduct"
    ></AdminPriceByProductDialog>
    <DeleteDialog
      v-model="product_price_remove_dialog"
      title="Delete Product Price"
      delete-button-text="Yes, Remove price"
      text-content="Are you sure you want to remove this product price?"
      @delete="handleRemoveProductPrice"
    />
  </v-card>
</template>

<script>
import request from '@/services/axios_instance'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { offers_mixin } from '../mixin'
import DeleteDialog from '@/common/DeleteDialog.vue'
import OrderDialog from '../components/OrderDialog.vue'
import AdminPriceByProductDialog from '../components/AdminPriceByProductDialog.vue'
export default {
  components: {
    DeleteDialog,
    OrderDialog,
    AdminPriceByProductDialog
  },
  mixins: [offers_mixin],
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('cart', {
      loading: 'loading',
      product: 'active_product'
    }),
    order_dialog: {
      get() {
        return this.$store.getters['cart/order_dialog']
      },
      set(val) {
        this.$store.commit('cart/set_order_dialog', val)
      }
    },
    is_product_owner() {
      return this.product && this.user.id === this.product.user_id
    }
  },
  data: () => ({
    activeAttachment: null,
    admin_price_by_product_dialog: false,
    product_price_remove_dialog: false
  }),
  watch: {
    '$route.params': {
      handler: function (val) {
        if (val && val.hasOwnProperty('id')) {
          this.fetchProduct({
            id: val.id,
            cb: () => {
              this.activeAttachment = this.product.previews.length
                ? this.product.previews[0]
                : null
            },
            err_cb: () => {
              this.$router.push({ name: 'not_found' })
            }
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions('cart', ['fetchProduct', 'likeActiveProduct']),
    ...mapMutations('cart', ['set_active_product']),
    goBack() {
      if (this.$route.query.hasOwnProperty('redirect_uri')) {
        this.$router.push({ path: this.$route.query.redirect_uri })
      } else {
        this.$router.go(-1)
      }
    },
    handleLiking() {
      this.likeActiveProduct((product, data) => {
        this.appSnackbar(data.message)
      })
    },
    handleOrdering() {
      this.order_dialog = true
    },
    handleEdit() {
      this.$router.push({
        name: 'product_builder',
        params: {
          id: this.product.id
        },
        query: {
          redirect_uri: this.$route.fullPath
        }
      })
    },
    handleSaveAdminPriceByProduct(payload) {
      request
        .post(`api/products/${this.product.id}/admin-prices`, payload)
        .then(({ data }) => {
          this.admin_price_by_product_dialog = false
          this.set_active_product(data)
          this.appSnackbar('Admin prices updated')
        })
    },
    handleRemoveProductPrice() {
      request
        .delete(`api/products/${this.product.id}/admin-prices`)
        .then(({ data }) => {
          this.product_price_remove_dialog = false
          this.set_active_product(data)
          this.appSnackbar('Admin prices removed')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.category-actions {
  position: absolute;
  top: 0;
  z-index: 2;
  height: 50px;
  width: 100%;
}
.subcategory-actions {
  position: absolute;
  top: 0;
  z-index: 3;
  height: 50px;
  width: 100%;
}
</style>
