<template>
  <CustomDialog
    v-model="dialog"
    :title="title"
    :has-footer="false"
    :max-width="800"
  >
    <template v-slot:content>
      <v-form v-model="valid">
        <v-card flat min-height="350">
          <v-card-text v-if="product">
            <v-row dense class="mb-1" align="stretch" justify="space-between">
              <v-col
                class="
                  border-1
                  border-color--grey
                  rounded-5
                  d-flex
                  pa-4
                  flex-column
                  align-center
                  justify-center
                "
              >
                <strong
                  class="
                    primary--text
                    fs-16
                    fullwidth
                    d-flex
                    align-center
                    justify-space-between
                    mb-2
                  "
                >
                  <span>Current Sell Price :</span>
                  <span>{{ product.adjusted_sell_price | money }}</span>
                </strong>
                <strong
                  class="
                    primary--text
                    fs-16
                    fullwidth
                    d-flex
                    align-center
                    justify-space-between
                    mb-2
                  "
                >
                  <span>Maximum Sell Price :</span>
                  <span>{{ product.sell_price | money }}</span>
                </strong>
                <v-carousel
                  v-model="carousel"
                  :continuous="true"
                  :cycle="true"
                  :show-arrows="false"
                  hide-delimiters
                  height="220"
                >
                  <v-carousel-item
                    v-for="(image, i) in product.previews"
                    :key="i"
                  >
                    <v-sheet
                      height="100%"
                      tile
                      color="white"
                      class="d-flex flex-column pa-2"
                    >
                      <v-row
                        style="background: #dedede"
                        class="fill-height flex-column"
                        align="center"
                        justify="center"
                      >
                        <Attachment
                          :item="image"
                          :prefer-thumb="false"
                          classes="ma-auto"
                          :styles="`max-height:100%;width: auto;`"
                        />
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
              </v-col>
              <v-col
                class="flex-column d-flex pa-4 align-center justify-center"
              >
                <v-radio-group
                  v-model="via"
                  row
                  class="my-2 primary--text"
                  hide-details="auto"
                >
                  <v-radio
                    off-icon="mdi-checkbox-blank-outline"
                    on-icon="mdi-checkbox-marked"
                    value="fixed"
                    label="Fixed price"
                  >
                  </v-radio>
                  <v-radio
                    off-icon="mdi-checkbox-blank-outline"
                    on-icon="mdi-checkbox-marked"
                    value="percentage"
                    label="Percentage"
                  >
                  </v-radio>
                </v-radio-group>
                <v-sheet
                  class="d-flex align-start flex-column"
                  v-if="via === 'fixed'"
                >
                  <label class="primary--text my-2 fw-500 fs-14 text-center">
                    Add additional price on this product by
                  </label>
                  <vuetify-money
                    outlined
                    clearable
                    dense
                    :rules="[minMaxRule(0.01, max_allowed_add)]"
                    value-when-is-empty="0.0"
                    v-model="fixed_value"
                    class="fullwidth"
                    placeholder="Enter additional fixed price"
                    :options="options_1"
                  ></vuetify-money>
                </v-sheet>
                <v-sheet
                  class="d-flex align-center flex-column"
                  v-if="via === 'percentage'"
                >
                  <label class="primary--text my-2 fw-500 fs-14 text-center">
                    Add additional price on this product by
                  </label>
                  <div
                    class="my-3 align-center d-flex justify-center flex-wrap"
                  >
                    <v-btn
                      @click="percentage_value = 5.0"
                      :color="
                        percentage_value === 5.0 ? 'primary' : 'secondary'
                      "
                      depressed
                      class="mr-1 text-none"
                    >
                      5%
                    </v-btn>
                    <v-btn
                      @click="percentage_value = 10.0"
                      :color="
                        percentage_value === 10.0 ? 'primary' : 'secondary'
                      "
                      depressed
                      class="text-none"
                    >
                      10%
                    </v-btn>
                    <v-btn
                      @click="percentage_value = 20.0"
                      :color="
                        percentage_value === 20.0 ? 'primary' : 'secondary'
                      "
                      depressed
                      class="mx-1 text-none"
                    >
                      20%
                    </v-btn>
                    <v-btn
                      @click="percentage_value = 30.0"
                      :color="
                        percentage_value === 30.0 ? 'primary' : 'secondary'
                      "
                      depressed
                      class="text-none"
                    >
                      30%
                    </v-btn>
                  </div>
                  <v-btn
                    block
                    color="light"
                    depressed
                    class="text-none primary--text border-1 rounded-10"
                    style="border-style: dotted"
                    @click="show_custom_percentage = !show_custom_percentage"
                  >
                    Add custom percentage
                  </v-btn>
                  <vuetify-money
                    outlined
                    v-if="show_custom_percentage"
                    clearable
                    dense
                    :rules="[minMaxRule(0.05, 1000.0)]"
                    :min="0.05"
                    :max="1000"
                    value-when-is-empty="0.0"
                    v-model="percentage_value"
                    class="fullwidth mt-3"
                    placeholder="Enter additional percentage on price"
                    :options="options_2"
                  ></vuetify-money>
                </v-sheet>
                <v-btn
                  width="150"
                  :disabled="!product || !valid"
                  @click="save"
                  color="primary"
                  class="align-self-center mb-3 mt-6 text-none"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
export default {
  name: 'AdminPriceByProductDialog',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    product: { type: Object }
  },
  computed: {
    max_allowed_add() {
      return this.product
        ? this.product.sell_price - this.product.adjusted_sell_price
        : 0.0
    },
    title() {
      return this.product ? `Add Admin Price for ${this.product.title}` : ''
    },
    payload() {
      return {
        product_id: this.product.id,
        via: this.via,
        via_value:
          this.via === 'fixed' ? this.fixed_value : this.percentage_value
      }
    }
  },
  data: () => ({
    valid: false,
    carousel: 0,
    dialog: false,
    via: 'percentage',
    percentage_value: 0.0,
    fixed_value: 0.0,
    show_custom_percentage: false,
    options_1: {
      locale: 'en-US',
      prefix: '$',
      suffix: '',
      length: 11,
      precision: 2
    },
    options_2: {
      locale: 'en-US',
      prefix: '',
      suffix: '%',
      length: 6,
      precision: 2
    }
  }),
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    save() {
      this.$emit('save', this.payload)
    }
  }
}
</script>
<style></style>
